<template>
  <v-container
    fluid
    class="bg-white">
    <p
      ref="Body"
      class="mt-4">
      Token
    </p>
    <div class="code">
      <div class="text-right">
        <a
          @click="copyToClipboard(token.value)">
          Copy
        </a>
      </div>

      <div>
        {{ token.value }}
      </div>
    </div>
  </v-container>
</template>

<script>
import { getAuthToken } from '@/assets/js/Authentication'
import copy2clipboard from 'copy-to-clipboard'

export default {
  data () {
    return {
      token: {
        value: ''
      }
    }
  },
  mounted () {
    this.token = getAuthToken()
  },
  methods: {
    copyToClipboard (text) {
      copy2clipboard(text)
      this.$store.dispatch('Components/setSnackbar', {
        value: true,
        message: 'copied to clipboard.',
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>
.code {
  background-color: #282828;
  color: #fff;
  border-radius: 4px;
  padding: 10px;
  overflow-wrap: break-word;
}
</style>
